<template>
  <v-row dense>
    <v-col cols="12" sm="6" md="4">
      <c-input v-model="value.name" :label="$t('shared.name')" :rules="'required|max:100'" dir="auto" />
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <c-input v-model="value.tel" :label="$t('shared.phone')" dir="auto" />
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <c-input v-model="value.fax" dir="ltr" :label="$t('organizations.fax')" />
    </v-col>

    <v-col cols="12">
      <c-input textarea v-model="value.address" :label="$t('shared.address')" :rules="'max:500'" />
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <uploader v-model="value.logo" :label="$t('shared.logo')" />
    </v-col>

    <v-col cols="12" sm="6" md="4" class="d-flex align-center">
      <v-switch v-model="value.active" :label="$t('shared.active')" inset />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'BaseForm',
  props: {
    value: {type: Object, default: () => ({})}
  },
  data() {
    return {
      genderItems: [
        {text: this.$t('shared.choose'), value: null},
        {text: this.$t('shared.male'), value: 'MALE'},
        {text: this.$t('shared.female'), value: 'FEMALE'}
      ]
    }
  }
}
</script>
